<template>
  <div class="body">
    <div class="title_box">
      <!-- 标题文字 -->
      <div class="title_box_font">
        <div>竹</div>
        <div>梓</div>
        <div class="z_status">
          <div class="z_light"></div>这个b班一天都不想上了
        </div>
      </div>
      <!-- 右下角吉祥物 -->
      <div class="img_icon">
        <img src="https://zhuzi-inu.oss-cn-beijing.aliyuncs.com/background/nullIcon.png" alt=""/>
      </div>
    </div>
    <div class="foot">
      <a href="https://beian.miit.gov.cn/" target="_blank">Icp备案号 渝ICP备2022005856号-1</a>
    </div>
  </div>
</template>

<script>
import {startSakura} from "@/views/sakura";

export default {
  data() {
    return {
      // 随机的颜色池
      colors: [
        '#f07c82', '#ee3f4d', '#de1c31', '#ef475d',
        '#f0c9cf', '#ee4866', '#eea6b7', '#ec2c64',
        '#ee2c79', '#93b5cf', '#baccd9', '#a4cab6'
      ]
    }
  },
  mounted() {
    startSakura()
    window.addEventListener('keydown', this.keyDownEvent)
  },
  methods: {
    /**
     * 监听键盘按下事件, 并且在页面随机展示按下的按键
     *
     * @param event 按下的事件回调信息
     */
    keyDownEvent(event) {

      // 获取窗口大小 按下按键
      let key = event.key
      let width = window.innerWidth;
      let height = window.innerHeight;

      // 得到元素随机出现的位置
      let top = Math.random() * height
      let left = Math.random() * width

      // 开始创建随机元素
      // 1. 创建div
      let divElement = document.createElement("div");
      divElement.className = "random_div"
      divElement.style.position = "fixed"
      divElement.style.top = top + "px"
      divElement.style.left = left + "px"
      divElement.style.height = "100px"
      divElement.style.width = "100px"
      divElement.style.fontSize = "100px"
      divElement.style.display = "flex"
      divElement.style.alignItems = "center"
      divElement.style.justifyContent = "center"
      let color = this.randomColor()
      divElement.style.color = color
      divElement.style.transition = "0.5s ease"

      // 2. 创建里面的字
      divElement.innerText = key
      // 设置字体外发光
      divElement.style.textShadow = '2px 2px 10px ' + color

      // 3. 将其加入到body的子元素中
      document.body.appendChild(divElement);

      // 1000ms 后删除该元素
      setTimeout(() => {
        divElement.remove()
      }, 1000)

    },

    /**
     * 根据颜色池随机返回颜色
     *
     * @returns {string} 颜色的16进制代码
     */
    randomColor() {
      let index = Math.floor(Math.random() * this.colors.length)
      return this.colors[index]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: url("https://zhuzi-inu.oss-cn-beijing.aliyuncs.com/background/liyo.jpg") center;
  background-size: cover;

  .title_box {
    position: fixed;
    top: 50px;
    box-shadow: 0 0.3px 0.7px rgba(0, 0, 0, 0.126), 0 0.9px 1.7px rgba(0, 0, 0, 0.179), 0 1.8px 3.5px rgba(0, 0, 0, 0.224), 0 3.7px 7.3px rgba(0, 0, 0, 0.277), 0 10px 20px rgba(0, 0, 0, 0.4);
    width: 46vw;
    height: 28vh;
    backdrop-filter: blur(20px);
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    .img_icon {
      position: absolute;
      bottom: -36px;
      right: -8px;
      img {
        width: 160px;
      }
    }

    .title_box_font {
      font-size: 4em;
      color: white;
      font-weight: 600;
      text-shadow: 2px 2px 6px #b2cf87;
      position: relative;
      display: flex;
      justify-content: space-around;
      width: 200px;

      .z_status {
        position: absolute;
        bottom: -22px;
        display: flex;
        font-size: .1em;
        letter-spacing: 1px;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-shadow: 2px 2px 6px #fedae2;

        .z_light {
          box-shadow: 0 0 6px #fedae2;
          height: 10px;
          width: 10px;
          border-radius: 10px;
          background-color: #fedae2;

          margin-right: 6px;
        }
      }
    }
  }

  .title_box:hover {
    box-shadow: 0 0.7px 1px rgba(0, 0, 0, 0.157), 0 1.7px 2.6px rgba(0, 0, 0, 0.224), 0 3.5px 5.3px rgba(0, 0, 0, 0.28), 0 7.3px 11px rgba(0, 0, 0, 0.346), 0 20px 30px rgba(0, 0, 0, 0.5);
  }

  .foot {
    position: fixed;
    bottom: 0;
    height: 3vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
      color: white;
      font-size: 12px;
    }
  }
}

@media (max-width: 840px) {
  .title_box_font {
    font-size: 2em !important;
  }
}

.random_div {
  height: 100px;
  width: 100px;
  background-color: blanchedalmond !important;
}
</style>